<script>
import simplebar from "simplebar-vue";
import Swal from "sweetalert2";
import General from "@/services/General";

export default {
  components: {
     simplebar,
  },
  data() {
    return {
      pageRoute: this.$route,
        side_menu_list: localStorage.getItem("access_objects"),
        languages: [
            {
              flag: require("@/assets/images/flags/us.jpg"),
              language: "en",
              title: "English",
            },
            {
              flag: require("@/assets/images/flags/french.jpg"),
              language: "fr",
              title: "French",
            },
            {
              flag: require("@/assets/images/flags/spain.jpg"),
              language: "es",
              title: "spanish",
            },
            {
              flag: require("@/assets/images/flags/china.png"),
              language: "zh",
              title: "Chinese",
            },
            {
              flag: require("@/assets/images/flags/arabic.png"),
              language: "ar",
              title: "Arabic",
            },
        ],
        helpItems:[],
        current_language: this.$i18n.locale,
        text: null,
        flag: null,
        value: null,
        loggedUserName: localStorage.getItem('username'),
        interval: null,
        side_bar_image_url: process.env.VUE_APP_SIDE_BAR_IMAGE,
        side_bar_image_long_url: process.env.VUE_APP_SIDE_BAR_IMAGE_LONG,
    };
  },
  mounted() {
      this.value = this.languages.find((x) => x.language === this.$i18n.locale);
      this.text = this.value.title;
      this.flag = this.value.flag;
      this.interval = setInterval( function(){ 
          this.checkSessionExpire();
      }.bind(this), 5000);
  },
  computed: {
    menuToArray(){
        return this.side_menu_list.split(',');
    }
  },
  async created() {
        await this.getDashboardHelp(); 
    },
  methods: {
    /**
     * Toggle menu
     */
    checkSessionExpire(){
        const tokenExpire = typeof localStorage.getItem('expire') !== 'undefined' ? localStorage.getItem('expire') : ''
        const currentTimestamp = Math.round(+new Date()/1000)

        if(tokenExpire !="" && tokenExpire != null && currentTimestamp > tokenExpire ){
          clearInterval(this.interval)
          localStorage.clear();
          Swal.fire({
              position: "top",
              icon: "warning",
              title: "Session has Expired !",
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Log In',
              allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                  path: "/login",
              });
            }
          })
        }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    logoutUser() {
      this.logout();
      this.$router.push({
        path: "/login",
      });
    },
    async getDashboardHelp() {
    try {
      const object=this.pageRoute.meta.accessObject;
        const response = await General.getDashboardHelp('object='+object);
        this.helpItems = response.data.data.map(item => ({
            id: item.id,          
            title: item.title,     
            pdf_url: item.pdf_url, 
            object: item.object 
        }));
    } catch (error) {
        this.error = error.response && error.response.data.error ? error.response.data.error : "Failed to load help items.";
    }
},


  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header justify-content-end">
      <div class="d-flex">

        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="side_bar_image_url" alt height="25" />
            </span>
            <span class="logo-lg">
              <img :src="side_bar_image_long_url" alt height="20" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="side_bar_image_url" alt height="25" />
            </span>
            <span class="logo-lg">
              <img :src="side_bar_image_long_url" alt height="20" />
            </span>
          </router-link>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
<!--        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="uil-search"></span>
          </div>
        </form>-->
      </div>
      <div class="d-flex">
  <b-dropdown
  v-if="helpItems.length > 0" 
  variant="white"
  class="dropdown d-inline-block"
  toggle-class="header-item"
  right
  menu-class="dropdown-menu-lg p-0 dropdown-menu-end shadow"
>
  <template v-slot:button-content>
    <i class="uil-question-circle d-none d-xl-inline-block font-size-20"></i>
    <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">Help</span>
    <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
  </template>

  <simplebar style="max-height: 230px" data-simplebar>
    <div>
      <ul class="list-unstyled p-2">
        <li v-for="item in helpItems" :key="item.id">
          <a :href="item.pdf_url" class="dropdown-item d-flex align-items-center" target="_blank">
            <i class="mdi mdi-file-pdf-outline me-2 text-danger"></i>
            <span>{{ item.title }}</span>
          </a>
        </li>
      </ul>
    </div>
  </simplebar>
</b-dropdown>
</div>




      <div class="d-flex">
<!--        <b-dropdown
          variant="white"
          class="d-inline-block d-lg-none ms-2"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-search"></i>
          </template>
          <form class="p-3 d-none">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('navbar.search.text')"
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>-->


<!--        <b-dropdown-->
<!--          variant="white"-->
<!--          class="dropdown d-inline-block"-->
<!--          toggle-class="header-item noti-icon"-->
<!--          right-->
<!--          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"-->
<!--        >-->


<!--          <div class="p-3">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col">-->
<!--                <h5 class="m-0 font-size-16">-->
<!--                  {{ $t("navbar.dropdown.notification.text") }}-->
<!--                </h5>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <a href="#!" class="small">{{-->
<!--                  $t("navbar.dropdown.notification.subtext")-->
<!--                }}</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <simplebar style="max-height: 230px" data-simplebar>-->
<!--            <a href class="text-reset notification-item">-->
<!--              <div class="media">-->
<!--                <div class="avatar-xs me-3">-->
<!--                  <span-->
<!--                    class="avatar-title bg-primary rounded-circle font-size-16"-->
<!--                  >-->
<!--                    <i class="uil-shopping-basket"></i>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="media-body">-->
<!--                  <h6 class="mt-0 mb-1">-->
<!--                    {{ $t("navbar.dropdown.notification.order.title") }}-->
<!--                  </h6>-->
<!--                  <div class="font-size-12 text-muted">-->
<!--                    <p class="mb-1">-->
<!--                      {{ $t("navbar.dropdown.notification.order.text") }}-->
<!--                    </p>-->
<!--                    <p class="mb-0">-->
<!--                      <i class="mdi mdi-clock-outline"></i>-->
<!--                      {{ $t("navbar.dropdown.notification.order.time") }}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a href class="text-reset notification-item">-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  src="@/assets/images/users/avatar-3.jpg"-->
<!--                  class="me-3 rounded-circle avatar-xs"-->
<!--                  alt="user-pic"-->
<!--                />-->
<!--                <div class="media-body">-->
<!--                  <h6 class="mt-0 mb-1">-->
<!--                    {{ $t("navbar.dropdown.notification.james.title") }}-->
<!--                  </h6>-->
<!--                  <div class="font-size-12 text-muted">-->
<!--                    <p class="mb-1">-->
<!--                      {{ $t("navbar.dropdown.notification.james.text") }}-->
<!--                    </p>-->
<!--                    <p class="mb-0">-->
<!--                      <i class="mdi mdi-clock-outline"></i>-->
<!--                      {{ $t("navbar.dropdown.notification.james.time") }}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a href class="text-reset notification-item">-->
<!--              <div class="media">-->
<!--                <div class="avatar-xs me-3">-->
<!--                  <span-->
<!--                    class="avatar-title bg-success rounded-circle font-size-16"-->
<!--                  >-->
<!--                    <i class="uil-truck"></i>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="media-body">-->
<!--                  <h6 class="mt-0 mb-1">-->
<!--                    {{ $t("navbar.dropdown.notification.item.title") }}-->
<!--                  </h6>-->
<!--                  <div class="font-size-12 text-muted">-->
<!--                    <p class="mb-1">-->
<!--                      {{ $t("navbar.dropdown.notification.item.text") }}-->
<!--                    </p>-->
<!--                    <p class="mb-0">-->
<!--                      <i class="mdi mdi-clock-outline"></i>-->
<!--                      {{ $t("navbar.dropdown.notification.item.time") }}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->

<!--            <a href class="text-reset notification-item">-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  src="@/assets/images/users/avatar-4.jpg"-->
<!--                  class="me-3 rounded-circle avatar-xs"-->
<!--                  alt="user-pic"-->
<!--                />-->
<!--                <div class="media-body">-->
<!--                  <h6 class="mt-0 mb-1">-->
<!--                    {{ $t("navbar.dropdown.notification.salena.title") }}-->
<!--                  </h6>-->
<!--                  <div class="font-size-12 text-muted">-->
<!--                    <p class="mb-1">-->
<!--                      {{ $t("navbar.dropdown.notification.salena.text") }}-->
<!--                    </p>-->
<!--                    <p class="mb-0">-->
<!--                      <i class="mdi mdi-clock-outline"></i>-->
<!--                      {{ $t("navbar.dropdown.notification.salena.time") }}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->
<!--          </simplebar>-->
<!--          <div class="p-2 border-top">-->
<!--            <div class="d-grid">-->
<!--              <a-->
<!--                class="btn btn-sm btn-link font-size-14 text-center"-->
<!--                href="javascript:void(0)"-->
<!--              >-->
<!--                <i class="uil-arrow-circle-right me-1"></i>-->
<!--                {{ $t("navbar.dropdown.notification.button") }}-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-dropdown>-->

        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
              <!-- <img
                class="rounded-circle header-profile-user"
                src="@/assets/images/users/avatar-generic.png"
                alt="Header Avatar"
              /> -->
            
              <i class="uil uil-user d-none d-xl-inline-block font-size-20"></i>
              <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{ loggedUserName }}</span>
              <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          <a class="dropdown-item" href="/profile" v-if="menuToArray.includes('profile')">
              <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
              <span class="align-middle">{{ $t("navbar.dropdown.marcus.list.profile")}}</span>
          </a>
           <a class="dropdown-item d-block" href="/users" v-if="menuToArray.includes('users') && (isSuperAdmin || isMonitorAdmin)">
               <i class="uil-users-alt font-size-18 align-middle text-muted me-1"></i>
            <span class="align-middle">{{$t("navbar.dropdown.marcus.list.users")}}</span>
          </a>
          <a class="dropdown-item" href="/logout">
              <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
              <span class="align-middle">{{ $t("navbar.dropdown.marcus.list.logout") }}</span>
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <!--<button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="uil-cog toggle-right"></i>
          </button>-->
        </div>
      </div>
    </div>
  </header>
</template>
